body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

/*
  Custom styling for the login page image
*/

.imgHeader {
    margin-top: 10vh;
    margin-bottom: 10px;
    width: 100%;
    object-fit: fill;
}


/*
The css selector below applies to all Avatar Image Components

By setting the object-fit to contain, 
it prevents the image from being cutoff if oversized
*/

[class*="MuiAvatar-img"] {
    object-fit: contain;
    border-radius: 0;
}


/*
The css selector below applies to all select "dropdown" elements

This just keeps them from dissapearing behind the the top header bar
*/

[class*="MuiMenu-root"] {
    z-index: 10000;
}


/*
    tableIcon used to minimize unecessary padding for cells that contain icons
*/

.tableIcon {
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 2px;
    padding-bottom: 2px;
}


/*
    Stylized Rounding used on titles
*/

.roundingBorder {
    border-radius: 15px 50px;
    border: 1px;
    border-style: solid;
    padding-right: 10px;
    padding-left: 10px;
}


/*
    Fill the container with the primary color and changes the text color to white
*/

.primaryFill {
    border-color: black;
    color: white;
    background-color: #304D6D;
}


/*
    Applies to Map Popup styles enabling a custom color
*/

.mapboxgl-popup-content {
    padding: 0;
    background-color: transparent;
    border: 0;
}


/*
    Styles the pointer for the selected lift. The "selectedLiftColor" variable is set within the component
*/

.mapCenter>.maplibregl-popup-tip {
    border-top-color: var(--selectedLiftColor, #1976d2);
    border-bottom-color: var(--selectedLiftColor, #1976d2);
}


/*
    styles the pointers for recommended locations
*/

.mapMarker1>.maplibregl-popup-tip {
    border-top-color: var(--marker1Color, #26be72);
    border-bottom-color: var(--marker1Color, #26be72);
}


/*
    styles the pointers for recommended locations
*/

.mapMarker2>.maplibregl-popup-tip {
    border-top-color: var(--marker2Color, #26be72);
    border-bottom-color: var(--marker2Color, #26be72);
}


/*
    styling necessary for backdrops to display only over a component
*/

.backdropParent {
    position: relative;
    z-index: 0;
}

.backdropChild {
    position: absolute;
}

.backdropChild2 {
    position: absolute;
    border-radius: 20px;
}


/*
    To fix known styling bug on the "MUI DateRangePicker" in front of other elements.
*/

.zIndexStyle1 {
    z-index: 10000;
}


/*
    used to style many of the input fields
*/

.whiteHover {
    background-color: #FFFFFF25;
}

.whiteHover:hover {
    background-color: #FFFFFF50;
}